import "../../static/css/reset.css";
import "../../static/css/global.css";
import "../../static/css/page.css";
import "../../static/css/noscript.css";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useDeviceDetect from "Utils/isMobile";
import Layout from "Utils/layout";
import * as Cookies from "Utils/cookies";
import Logo from "Components/Logo/Logo";
import PleaseRotate from "Components/PleaseRotate/PleaseRotate";

const Content = styled.div`
  display: ${({ visible }) => (visible ? "block!important" : "none")};
  width: 100%;
  height: 100%;
`;

const NoScript = styled.div`
  display: ${({ visible }) => (visible ? "block!important" : "none")};
`;

const Page = (props) => {
  const [js, setJs] = useState(false);
  const { isMobile } = useDeviceDetect();

  // if js is supported, we display the content
  useEffect(() => {
    setJs(true);
  }, []);

  useEffect(() => {
    Cookies.set("mdv", isMobile ? 1 : 0);
  }, [isMobile]);

  const { javascriptSupport, rotateDevice } = props;

  return (
    <>
      <PleaseRotate message={rotateDevice} gameVisible={props.gameVisible} />
      <Layout gameVisible={props.gameVisible} muteFill={props.muteFill}>
        <Content className="content" visible={js}>
          {props.children}
        </Content>

        <NoScript className="noscript" visible={!js}>
          <div className="noscript__content">
            <Logo />
            <h2 className="noscript__header">{javascriptSupport}</h2>
          </div>
        </NoScript>
      </Layout>
    </>
  );
};

export default Page;
