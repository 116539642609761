/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.div`
	position: absolute;
	display: block;
	z-index: 100;
	top: 24px;
	left: 24px;
	width: 7px;
	height: 10px;
	cursor: pointer;
	pointer-events: all;

	[dir="rtl"] & {
		top: 29px;
		right: 24px;
		left: initial;
		transform: rotate(180deg);
	}

	&:hover {
		svg {
			opacity: 0.5;
		}
	}
`;

/* Main Component */
const BackButton = (props) => {
	const width = props.width || "7";
	const height = props.height || "10";
	const viewBox = `0 0 ${width} ${height}`;

	return (
		<Wrapper onClick={props.onClick}>
			<svg
				width={width}
				height={height}
				viewBox={viewBox}
				fill="#000000"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6.37132 8.5L4.95711 9.91421L0 4.95711L4.95711 0L6.37132 1.41421L2.82843 4.95711L6.37132 8.5Z" />
			</svg>
		</Wrapper>
	);
};
export default BackButton;
