import React, { useEffect, useState, useRef } from "react";
import * as Cookies from "Utils/cookies";
import Analytics from "Utils/analytics";
import Broadcaster from "Utils/Broadcaster";
import LoadingAnimation from "Components/LoadingAnimation/LoadingAnimation";
import Logo from "Components/Logo/Logo";
import { ON_LANDING_LOADED } from "Utils/const";
import AppLink from "Components/AppLink/AppLink";
import Checkbox from "Components/Checkbox/Checkbox";
import PrimaryButton from "Components/PrimaryButton/PrimaryButton";
import Section from "Components/Section/Section";
import SoundManager from "Utils/SoundManager";
import styled from "styled-components";
import ShopFragrance from "Components/ShopFragrance/ShopFragrance";

const Content = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.23);
`;

const Link = styled.a`
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration: ${({ nodecoration }) =>
    nodecoration ? "none" : "underline"};
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const Background = styled.div`
  background-color: ${({ opaque }) => (opaque ? "#B19878" : "transparent")};
  position: fixed;
  top: -25vh;
  left: 0;
  width: 100%;
  height: 150vh;
  transition: background-color 0.4s;
`;

const ReadyContent = styled.div`
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  position: absolute;
`;

const Language = styled(ReadyContent)`
  top: 24px;
  left: 0;
  width: 100%;
  text-align: center;
  transition: opacity 0.5s;
`;

const BeginGame = styled(ReadyContent)`
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 17px;
  left: 50%;
  max-width: 382px;
  padding: 24px;
  transform: translate(-50%, 0);
  transition: opacity 0.5s;
  width: 100%;
`;

const BurberryLogo = styled.div`
  position: absolute;
  width: 225px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const BrandLink = styled(ShopFragrance)`
  color: #ffffff;
  margin-top: 30px;
`;

const Splash = (props) => {
  const [ready, setReady] = useState(!props.animate);
  const [opaque, setOpaque] = useState(props.animate);
  const [errorTerms, setErrorTerms] = useState(false);
  const [errorAge, setErrorAge] = useState(false);
  // const [checkedTerms, setCheckedTerms] = useState(false);
  // const [checkedAge, setCheckedAge] = useState(false);

  const refTermsAgree = useRef();
  const refAgeAgree = useRef();

  const { data } = props;
  const { landingPage } = data.localesJson;
  const { language } = data.localesJson.metadata;
  const {
    termsConfirm,
    ageConfirm,
    errorTermsConfirm,
    errorAgeConfirm,
    playButton,
  } = landingPage;
  const { brandUrl, brandName } = data.localesJson.global;

  const checkedTerms = Cookies.get(`acceptterms`) === "true";
  const checkedAge = Cookies.get(`acceptage`) === "true";

  useEffect(() => {
    SoundManager.fade("intro_soundscape_loop", 0, 2);
    Analytics.setup(props.ga_uid);
    Analytics.trackPageView("/loading");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Broadcaster.on(ON_LANDING_LOADED, handleAssetsLoaded);
    return () => {
      Broadcaster.off(ON_LANDING_LOADED, handleAssetsLoaded);
    };
  }, []);

  function handleAssetsLoaded() {
    Analytics.trackPageView("/landing");
    setReady(true);
    setOpaque(false);
  }

  function transitionLocale() {
    setReady(false);
    window.navigateTo("/languages");
  }

  function transitionMarket() {
    const termsAgree = refTermsAgree.current.checked;
    const ageAgree = refAgeAgree.current.checked;

    if (termsAgree && ageAgree) {
      window.navigateTo("/landing");
      SoundManager.play("ui_click");
      SoundManager.play("intro_soundscape_loop");
      setReady(false);
    } else {
      setErrorTerms(!termsAgree);
      setErrorAge(!ageAgree);
      if (!termsAgree) Analytics.trackError("errorTermsAgree");
      if (!ageAgree) Analytics.trackError("errorAgeConfirm");
    }
  }

  function saveCookieTerms() {
    setTimeout(() => {
      if (refTermsAgree.current)
        Cookies.set(`acceptterms`, refTermsAgree.current.checked);
    }, 100);
  }

  function saveCookieAgeGate() {
    setTimeout(() => {
      if (refAgeAgree.current)
        Cookies.set(`acceptage`, refAgeAgree.current.checked);
    }, 100);
  }

  const partOne = termsConfirm.substr(0, termsConfirm.indexOf("%"));
  const partTwo = termsConfirm
    .substr(
      termsConfirm.indexOf("%"),
      termsConfirm.lastIndexOf("%") - termsConfirm.indexOf("%")
    )
    .split("%")
    .join("");
  const partThree = termsConfirm.substr(termsConfirm.lastIndexOf("%") + 1);

  const termsConfirmSentence = (
    <>
      {partOne}
      <AppLink
        href={`/terms`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {partTwo}
      </AppLink>
      {partThree}
    </>
  );

  return (
    <Content>
      <Background opaque={opaque} />
      <Section background={props.gameVisible ? "none" : props.background}>
        <Language visible={ready}>
          <Link onClick={transitionLocale}>{language}</Link>
        </Language>
        {ready && (
          <BurberryLogo>
            <Logo dark={!opaque || ready} width="225" height="60" />
          </BurberryLogo>
        )}
        {!ready && (
          <Loading>
            <LoadingAnimation black />
          </Loading>
        )}
        {ready && (
          <BeginGame visible={ready}>
            <PrimaryButton label={playButton} onClick={transitionMarket} />
            <Checkbox
              checked={checkedTerms}
              ref={refTermsAgree}
              error={errorTerms}
              errorMessage={errorTermsConfirm}
              onClick={saveCookieTerms}
            >
              {termsConfirmSentence}
            </Checkbox>
            <Checkbox
              checked={checkedAge}
              ref={refAgeAgree}
              error={errorAge}
              errorMessage={errorAgeConfirm}
              onClick={saveCookieAgeGate}
            >
              {ageConfirm}
            </Checkbox>
            <BrandLink
              href={brandUrl}
              label={brandName}
              onClick={(e) => {
                e.preventDefault();
                Analytics.trackClick("shop", "brandLink");
              }}
            />
          </BeginGame>
        )}
      </Section>
    </Content>
  );
};

export default Splash;
