class KeyboardStatus {
	constructor() {
		if (typeof window !== "undefined") {
			window.KeyboardStatus = this;
		}
		this.open = false;
	}

	setOpen(bool) {
		if (typeof window == "undefined") {
			return;
		}
		this.open = bool;
	}

	getOpen() {
		if (typeof window == "undefined") {
			return;
		}
		return this.open;
	}
}

export default new KeyboardStatus();
