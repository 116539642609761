import * as Cookies from "Utils/cookies";

import { MUTED, UNMUTED } from "Utils/const";
import React, { useEffect, useState } from "react";

import Broadcaster from "Utils/Broadcaster";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.div`
	position: absolute;
	display: ${({ visible }) => (visible ? "block" : "none")};
	z-index: 9000;
	top: 23px;
	right: 19px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	pointer-events: all;

	[dir="rtl"] & {
		right: initial;
		left: 45px;
	}

	&:hover {
		svg {
			opacity: 0.5;
		}
	}
`;

/* Main Component */
const MuteButton = (props) => {
	const width = props.width || "22";
	const height = props.height || "22";
	const viewBox = `0 0 30 30`;
	const fill = props.fill || `#ffffff`;

	const [muted, setMuted] = useState(
		false || Cookies.get("")
	);

	const speakerPart =
		"M15 1.5L6.43669 8.6777H1.5V20.0461H6.37591L15 27.1612V1.5ZM7.57332 11.5198L12 7.8094V20.9174L7.49909 17.204H4.5V11.5198H7.57332ZM26.3709 9.43325L28.4922";
	const mutePart =
		"11.4429L25.411 14.362L28.4915 17.2803L26.3701 19.29L23.2897 16.3716L20.2092 19.29L18.0879 17.2803L21.1684 14.362L18.0871 11.4429L20.2085 9.43325L23.2897 12.3523L26.3709 9.43325Z";

	const loudPart = "0";

	useEffect(() => {
		Broadcaster.on(MUTED, () => {
			setMuted(true);
		});
		Broadcaster.on(UNMUTED, () => {
			setMuted(false);
		});
	}, []);

	const path = muted
		? `${speakerPart} ${mutePart}`
		: `${speakerPart} ${loudPart}`;

	return (
		<Wrapper
			onClick={props.onClick}
			visible={props.visible}
		>
			<svg
				width={width}
				height={height}
				viewBox={viewBox}
				fill={fill}
				xmlns="http://www.w3.org/2000/svg"
			>
				<g id="Icon / S / VolumeOffIcon">
					<g id="New / S / VolumeOffIcon">
						<path
							id="Colour"
							fillRule="evenodd"
							clipRule="evenodd"
							d={path}
						/>
					</g>
				</g>
			</svg>
		</Wrapper>
	);
};
export default MuteButton;
