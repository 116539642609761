import * as Cookies from "Utils/cookies";

import { useEffect, useState } from "react";

import LeaderboardLine from "./LeaderboardLine";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  margin: 0 auto;

  @media (max-height: 566px) {
    margin-left: -28px;
    width: 100vw;
  }
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.23);
`;

export const HighestTitle = styled.div`
  width: 100%;
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
`;

/* Main Component */
const Leaderboard = (props) => {
  const [highscores, setHighscores] = useState(null);
  useEffect(() => {
    if (props.data && props.data.highscores) {
      let data = [...props.data.highscores];
      let i = 0;
      const tmp = [];
      const maxItems = 5;

      while (i < maxItems) {
        if (data[i]) {
          let initials = data[i].initials;
          let value = data[i].score;
          let isPlayer = Cookies.get("uuid") === data[i].uuid;
          if (isPlayer) {
            initials = Cookies.get("initials");
          }
          tmp.push(
            <LeaderboardLine
              key={i}
              rank={Number(i + 1)}
              distance={value}
              name={initials}
              highlighted={isPlayer}
            />
          );
        } else {
          tmp.push(
            <LeaderboardLine
              key={i}
              rank=""
              distance=""
              name=""
              highlighted={false}
            />
          );
        }
        i++;
      }
      setHighscores(tmp);
    }
  }, [props.data]);

  const {
    rank,
    distance,
    name,
    yourhighest,
    yourlast,
    refInitials,
    errorInitials,
    userInitials,
    handleInitialsInput,
    initialsInputLabel,
    initialsInputError,
  } = props;

  return (
    <Wrapper>
      {props.loading && (
        <Loading>
          <LoadingAnimation black />
        </Loading>
      )}
      {!props.loading && (
        <>
          <LeaderboardLine
            rank={rank}
            distance={distance}
            name={name}
            highlighted={true}
          />
          {highscores}
          {Cookies.get("initials") && (
            <>
              <HighestTitle>{yourhighest}</HighestTitle>
              <LeaderboardLine
                rank={Cookies.get("highestPosition") || "_"}
                distance={Cookies.get("highestScore") || "_"}
                name={Cookies.get("initials") || "_"}
              />
            </>
          )}
          <HighestTitle>{yourlast}</HighestTitle>
          <LeaderboardLine
            rank={props.position || "_"}
            distance={props.score}
            name={Cookies.get("initials") || "_"}
            refInitials={refInitials}
            yourhighest={yourhighest}
            errorInitials={errorInitials}
            userInitials={userInitials}
            handleInitialsInput={handleInitialsInput}
            initialsInputLabel={initialsInputLabel}
            initialsInputError={initialsInputError}
            requiresInput
          />
        </>
      )}
    </Wrapper>
  );
};
export default Leaderboard;
