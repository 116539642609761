import LoadingAnimation from "Components/LoadingAnimation/LoadingAnimation";
import React from "react";
import styled from "styled-components";

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #b19878;
`;

const Loading = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.23);
`;

const Preparing = () => {
  return (
    <Content>
      <Loading>
        <LoadingAnimation black />
      </Loading>
    </Content>
  );
};

export default Preparing;
