/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.button`
	display: block;
	border-radius: 0;
	background-color: ${({ disabled }) =>
		disabled ? "#cccccc" : "#000000"};
	pointer-events: ${({ disabled }) =>
		disabled ? "none" : "inherit"};

	/* max-width: 240px; */
	width: 100%;
	height: 44px;

	padding: 15px 0;
	margin-bottom: 16px;

	font-family: inherit;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: center;

	border: 0;

	color: #ffffff;

	cursor: pointer;

	box-sizing: border-box;

	&:hover {
		background-color: transparent;

		border: 1px solid #000000;
		padding: 13px 0;

		color: #000000;
	}

	&:focus {
		background-color: #000000;
		color: #ffffff;
		outline: none;
	}
`;

/* Main Component */
const TertiaryButton = (props) => {
	function handleClick(e) {
		e.target.blur();
		if (props.onClick) props.onClick();
	}

	return (
		<Wrapper
			className={props.className}
			onClick={handleClick}
			disabled={props.disabled}
		>
			{props.label}
		</Wrapper>
	);
};
export default TertiaryButton;
