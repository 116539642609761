/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.div`
	position: absolute;
	display: block;
	z-index: 100;
	top: 18px;
	right: 13px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	pointer-events: all;

	[dir="rtl"] & {
		left: 13px;
		right: initial;
	}

	&:hover {
		svg {
			opacity: 0.5;
		}
	}
`;

/* Main Component */
const CloseButton = (props) => {
	const width = props.width || "16";
	const height = props.height || "16";
	const viewBox = `0 0 ${width} ${height}`;

	return (
		<Wrapper onClick={props.onClick}>
			<svg
				width={width}
				height={height}
				viewBox={viewBox}
				fill="#000000"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M0 12.6L12.6 0L14 1.4L1.4 14L0 12.6Z" />
				<path d="M1.4 0L14 12.6L12.6 14L0 1.4L1.4 0Z" />
			</svg>
		</Wrapper>
	);
};
export default CloseButton;
