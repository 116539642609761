import * as Cookies from "Utils/cookies";
import { H2, P } from "Components/Typography/Typography";
import React from "react";
import Analytics from "Utils/analytics";
import Overlay from "Components/Overlay/Overlay";
import TertiaryButton from "Components/TertiaryButton/TertiaryButton";
import ShopFragrance from "Components/ShopFragrance/ShopFragrance";
import styled from "styled-components";

const HeaderH2 = styled(H2)`
  max-width: 180px;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Overview = styled.div`
  position: relative;
  margin: 0 auto;
`;

const OverviewGif = styled.img`
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  max-width: 240px;
`;

const OverviewGifFirstFrame = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
`;

const BottomContent = styled.div`
  width: calc(100% - 48px);
  margin: 0 auto;
`;

const Instructions = styled.div`
  width: 100%;
`;

const Instruction = styled(P)`
  text-align: center;
  width: calc(100% - 80px);
  margin: 0 auto 5px auto;
`;

const Landing = (props) => {
  const market = props.market;
  Cookies.set("market", market);

  const { data } = props;
  const { introPage, global } = data.localesJson;
  let { title, instructionOne, instructionTwo, startButton } = introPage;
  let { brandUrl, brandName } = global;

  function handleContinueClick() {
    window.navigateTo("/preparing");
  }

  function handleBack() {
    window.navigateTo("back()");
  }

  return (
    <Overlay className={props.className} withBackButton callback={handleBack}>
      <Wrapper>
        <HeaderH2>{title}</HeaderH2>
        <Overview>
          <OverviewGif
            src="/assets/images/overview.gif"
            alt="Game instructions"
          />
          <OverviewGifFirstFrame
            src="/assets/images/overview-first-frame.jpg"
            alt="Game instructions"
          />
        </Overview>
        <Instructions>
          <Instruction dangerouslySetInnerHTML={{ __html: instructionOne }} />
          <Instruction dangerouslySetInnerHTML={{ __html: instructionTwo }} />
        </Instructions>
        <BottomContent>
          <TertiaryButton label={startButton} onClick={handleContinueClick} />
          <ShopFragrance href={brandUrl} label={brandName} />
        </BottomContent>
      </Wrapper>
    </Overlay>
  );
};

export default Landing;
