/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.button`
	display: block;
	border-radius: 0;
	/* max-width: 240px; */
	width: 100%;
	height: 44px;

	background-color: transparent;

	border: 1px solid #000000;
	padding: 13px 0;
	margin-bottom: 16px;

	color: #000000;

	font-family: inherit;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	text-align: center;

	color: #000000;

	cursor: pointer;

	box-sizing: border-box;

	&:hover {
		background-color: #000000;

		border: 0;
		padding: 15px 0;

		color: #ffffff;
	}

	&:focus {
		background-color: transparent;
		color: #000000;
		border: 1px solid #000000;
		outline: none;
	}

	@media (max-width: 767px) {
		&:hover,
		&:focus {
			background-color: transparent;
			color: #000000;
			outline: none;
			border: 0;
		}
	}
`;

/* Main Component */
const SecondaryButton = (props) => {
	function handleClick(e) {
		e.target.blur();
		if (props.onClick) props.onClick(e);
	}

	return (
		<Wrapper
			className={props.className}
			onClick={handleClick}
			disabled={props.disabled}
		>
			{props.label}
		</Wrapper>
	);
};
export default SecondaryButton;
