/* Dependencies*/
import React, { useState } from "react";
import styled from "styled-components";
import AppLink from "Components/AppLink/AppLink";
import Analytics from "../../utils/analytics";
/* Component Styles */

const Link = styled(AppLink)`
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 5px;
`;

/* Main Component */

const ShopFragrance = (props) => {
  const { href, label, className } = props;

  return (
    <Link
      href={href}
      target="_blank"
      className={className}
      onClick={(e) => {
        Analytics.trackClick("shop", "brandLink");
      }}
    >
      {label}
    </Link>
  );
};
export default ShopFragrance;
