/* Dependencies*/
import React, { useState } from "react";

import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.div`
  display: block;

  position: relative;
  padding-left: 25px;
  margin-bottom: ${({ error }) => (error ? "0" : "14px")};
  cursor: pointer;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: 25px;
  }
`;

const Label = styled.label`
  display: inline-block;
  color: #ffffff;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;

  height: 0;
  width: 0;

  cursor: pointer;
`;

const CheckMark = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ error }) => (error ? "#ED1C24" : "#ffffff")};

  [dir="rtl"] & {
    left: 0px;
    right: 3px;
  }

  &:after {
    content: "";
    position: absolute;
    display: ${({ checked }) => (checked ? "block" : "none")};
    left: 5px;
    top: 2px;
    width: 6px;
    height: 9px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const ErrorMessage = styled.p`
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  position: relative;
  left: 0;
  width: 100%;

  color: #ed1c24;
  margin-top: 5px;
  margin-bottom: 10px;
`;
/* Main Component */
const Checkbox = React.forwardRef((props, ref) => {
  const [checked, setChecked] = useState(props.checked || false);

  function toggleCheck() {
    setChecked(!checked);
    if (props.onClick) {
      props.onClick(!checked);
    }
  }

  return (
    <Wrapper
      onClick={toggleCheck}
      error={props.error}
      className={props.className}
    >
      <CheckMark checked={checked} error={props.error} />
      <Input
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={checked}
        readOnly
        ref={ref}
      />
      <Label>{props.children}</Label>
      {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </Wrapper>
  );
});
export default Checkbox;
