/* Dependencies*/
import React, { useEffect, useState } from "react";

import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.div`
	color: #ffffff;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	opacity: ${({ visible }) => (visible ? "1" : "0")};
	transition: opacity 0.2s;

	font-family: inherit;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;

	text-align: center;
`;

const DURATION = 3;
const DELAY = 600;

class CountDown extends React.Component {
	state = {
		count: DURATION,
		visible: false,
	};

	componentDidMount() {}

	componentWillUnmount() {
		this.stop();
	}

	reset = () => {
		clearInterval(this.interval);
		this.setState({
			count: DURATION,
		});
	};

	start = () => {
		this.setState({
			visible: true,
		});
		this.interval = setInterval(this.tick, DELAY);
	};

	stop = () => {
		if (this.interval) clearInterval(this.interval);
	};

	hide = () => {
		this.setState({
			visible: false,
		});
	};

	tick = () => {
		this.setState({
			count: this.state.count - 1,
		});
		if (this.state.count < 0) {
			this.stop();
			this.hide();
			if (this.props.callback) this.props.callback();
		}
	};

	render() {
		const message =
			this.state.count > 0
				? `${this.props.label}${" "}${this.state.count}`
				: this.props.go || "";
		return (
			<Wrapper visible={this.state.visible}>
				{message}
			</Wrapper>
		);
	}
}

export default CountDown;
