import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const CanvasElement = styled.canvas`
  visibility: hidden;
  opacity: 0;
`;

const Canvas = (props) => {
  const { canvas } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    canvas(canvasRef.current);
  }, [canvas]);

  return <CanvasElement ref={canvasRef} canvas />;
};

export default Canvas;
