class PreCache {
  constructor() {
    this.name = "precache";
    this.assets = {};
  }

  init(assets) {
    assets.map((asset) => {
      this.assets[asset] = { loaded: false, url: asset, blob: null };
    });
  }

  getBlob(name) {
    return this.assets[name];
  }

  startLoading() {
    return new Promise((resolve) => {
      for (let a in this.assets) {
        let req = new XMLHttpRequest();
        const asset = this.assets[a];
        req.open("GET", asset.url, true);
        req.responseType = "blob";

        req.onload = function () {
          if (this.status === 200) {
            const blob = URL.createObjectURL(this.response);
            asset.blob = blob;
            asset.loaded = true;
          }
          resolve();
        };
        req.onerror = function () {
          resolve();
        };

        req.send();
      }
    });
  }
}

export default PreCache;
