import React, { useRef, useState, useEffect } from "react";
// Components
import Analytics from "Utils/analytics";
import * as Cookies from "Utils/cookies";
import BackButton from "Components/BackButton/BackButton";
import Leaderboard from "Components/Leaderboard/Leaderboard";
import Overlay from "Components/Overlay/Overlay";
import SecondaryButton from "Components/SecondaryButton/SecondaryButton";
import ShopFragrance from "Components/ShopFragrance/ShopFragrance";
import TertiaryButton from "Components/TertiaryButton/TertiaryButton";
import WebShare from "Components/WebShare/WebShare";
// Utils
import WebApi from "Utils/webapi";
import {
  BLACK_SCORE,
  GOLD_SCORE,
  SILVER_SCORE,
  BRONZE_SCORE,
} from "../../utils/const";
// Data
import bronze from "../../../static/assets/images/static_coins/bronze.png";
import silver from "../../../static/assets/images/static_coins/silver.png";
import gold from "../../../static/assets/images/static_coins/gold.png";
import black from "../../../static/assets/images/static_coins/black.png";
// Styles
import { Title, BottomGroup, OffsetContainer, Coin } from "./Tiers.styles";

const Tiers = (props) => {
  // Props
  const { data } = props;
  let {
    gameEndTitle,
    shareButton,
    leaderboardButton,
  } = data.localesJson.gameOverPage;
  let {
    highScoresTitle,
    rankHeading,
    distanceHeading,
    nameHeading,
    userHighScoreHeading,
    userLastScoreHeading,
    initialsInputLabel,
    initialsInputError,
  } = data.localesJson.leaderboardPage;
  let { scoreHeading, linkHeading, link } = data.localesJson.socialShare;
  let { brandUrl, brandName, playAgainButton } = data.localesJson.global;

  // State
  const [viewGameOver, setViewGameOver] = useState(true);
  const [viewLeaderboard, setViewLeaderboard] = useState(false);
  const [viewSocialShare, setViewSocialShare] = useState(false);
  const [gameOverTitle, setGameOverTitle] = useState(gameEndTitle);
  const [leaderboardData, setLeaderboardData] = useState(null);
  const [position, setPosition] = useState(false);
  const [medal, setMedal] = useState(undefined);
  const [leaderboardLoading, setLeaderboardLoading] = useState(true);
  const [errorInitials, setErrorInitials] = useState(false);
  const [userInitials, setUserInitials] = useState(
    Cookies.get("initials") || ""
  );
  const [initialsSubmitted, setInitialsSubmitted] = useState(false);

  // Refs
  const refInitials = useRef();

  // Set title on game over
  useEffect(() => {
    setGameOverTitle(gameEndTitle.split("%SCORE%").join(`${props.distance}`));
  }, [gameEndTitle, props.distance]);

  // Set medal on game over
  useEffect(() => {
    if (props.distance >= BLACK_SCORE) setMedal(black);
    else if (props.distance >= GOLD_SCORE) setMedal(gold);
    else if (props.distance >= SILVER_SCORE) setMedal(silver);
    else if (props.distance >= BRONZE_SCORE) setMedal(bronze);
    else return;
  }, [props.distance]);

  function handleShareScore() {
    setViewSocialShare(true);
  }

  function handleViewLeaderboard() {
    Analytics.trackPageView("/leaderboard");
    setViewGameOver(false);
    setLeaderboardLoading(true);
    setViewLeaderboard(true);
    if (userInitials !== "") {
      WebApi.enterLeaderboard(props.distance, Cookies.get("initials")).then(
        (res) => {
          setLeaderboardLoading(false);
          setPosition(res.position);
          setLeaderboardData(res);
        }
      );
    } else {
      WebApi.getLeaderboard().then((res) => {
        setLeaderboardLoading(false);
        setLeaderboardData(res);
      });
    }
  }

  function handleHideLeaderboard() {
    setViewLeaderboard(false);
    setViewGameOver(true);
  }

  const handleInitialsInput = (e) => {
    e.preventDefault();
    let userInitials = Cookies.get("initials") || refInitials.current.value;
    userInitials = userInitials.toUpperCase();
    const regInitials = /([A-Za-z]){2}/g;
    if (!regInitials.test(userInitials)) {
      setErrorInitials(true);
      Analytics.trackError("errorInitials");
    } else {
      setUserInitials(userInitials);
      Cookies.set("highestScore", props.distance);
      Cookies.set("initials", userInitials);
      setErrorInitials(false);
      setInitialsSubmitted(true);
      Analytics.trackClick("form", "addInitials");
    }
  };

  // Reload the leaderboard with users rank after initials are submitted
  useEffect(() => {
    if (initialsSubmitted) {
      handleViewLeaderboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialsSubmitted]);

  function handleReplay() {
    Analytics.trackClick("Gameplay", "playAgainButton");
    setViewGameOver(true);
    setViewLeaderboard(false);
    if (props.callback) {
      props.callback();
    }
  }

  function handleShareFinish() {
    setViewSocialShare(false);
  }

  return (
    <>
      {viewGameOver && (
        <Overlay className={props.className}>
          <OffsetContainer>
            <Coin src={medal} alt="coin" />
            <Title>{gameOverTitle}</Title>
          </OffsetContainer>
          {viewSocialShare && (
            <WebShare
              scoreHeading={scoreHeading}
              score={props.distance}
              linkHeading={linkHeading}
              link={link}
              finished={handleShareFinish}
            />
          )}
          <BottomGroup>
            <TertiaryButton label={shareButton} onClick={handleShareScore} />
            <SecondaryButton
              label={leaderboardButton}
              onClick={handleViewLeaderboard}
            />
            <SecondaryButton label={playAgainButton} onClick={handleReplay} />
            <ShopFragrance href={brandUrl} label={brandName} />
          </BottomGroup>
        </Overlay>
      )}
      {viewLeaderboard && (
        <Overlay className={props.className} withBackButton>
          <BackButton onClick={handleHideLeaderboard} />
          <Title>{highScoresTitle}</Title>
          <Leaderboard
            loading={leaderboardLoading}
            data={leaderboardData}
            position={position}
            score={props.distance}
            rank={rankHeading}
            distance={distanceHeading}
            name={nameHeading}
            yourhighest={userHighScoreHeading}
            yourlast={userLastScoreHeading}
            userInitials={userInitials}
            refInitials={refInitials}
            errorInitials={errorInitials}
            handleInitialsInput={handleInitialsInput}
            initialsInputLabel={initialsInputLabel}
            initialsInputError={initialsInputError}
          />
          <BottomGroup>
            <TertiaryButton label={playAgainButton} onClick={handleReplay} />
            <ShopFragrance href={brandUrl} label={brandName} />
          </BottomGroup>
        </Overlay>
      )}
    </>
  );
};

export default Tiers;
