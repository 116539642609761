import * as Cookies from "Utils/cookies";
import SoundManager from "Utils/SoundManager";

import {
  GAME_FADE_SOUND,
  GAME_PAUSE_SOUND,
  GAME_PLAY_SOUND,
  GAME_SEEK_SOUND,
  GAME_STATES,
  GAME_STOP_SOUND,
  GAME_UPDATE_RIDING_MODE,
  GAME_UPDATE_SCORE,
  GAME_UPDATE_STATE,
  ON_BOARDING_STEPS,
  ON_BOARDING_STEP_DONE,
  ON_UI_CHANGE,
} from "Utils/const";
import React, { useEffect, useRef, useState } from "react";

import Analytics from "Utils/analytics";
import Broadcaster from "Utils/Broadcaster";
import ContextMenu from "Utils/ContextMenu";
import CountDown from "Components/CountDown/CountDown";
import DistanceCounter from "Components/DistanceCounter/DistanceCounter";
import Tiers from "Components/Tiers/Tiers";
import Tips from "Components/Tips/Tips";
import WebApi from "Utils/webapi";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

// const Intro = styled.div`
//   opacity: ${({ visible }) => (visible ? "1" : "0")};
//   pointer-events: ${({ visible }) => (visible ? "all" : "none")};
//   transition: opacity 0.2s;
// `;

const UID = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  pointer-events: none;
  transition: opacity 0.2s;
`;

const Reward = styled(Tiers)`
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  pointer-events: ${({ visible }) => (visible ? "all" : "none")};
  transition: opacity 0.2s;
`;

const HorseBonus = styled.div`
  background-color: #ffffff;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.4s;
`;

const Home = (props) => {
  const { data } = props;
  const { gamePage } = data.localesJson;
  const { ready, go, tipOne, tipTwo, tipThree, tipFour, tipFive } = gamePage;

  const tips = [
    { text: tipOne, animation: true },
    { text: tipTwo },
    { text: tipThree },
    { text: tipFour },
    { text: tipFive },
  ];

  const refWebGlApp = useRef(null);

  const [playing, setPlaying] = useState(0);
  const [tutorial, setTutorial] = useState(false);
  const [uidVisible, setUidVisible] = useState(0);
  const [tierVisible, setTierVisible] = useState(0);
  const [score, setScore] = useState(props.showTier || 0);
  const [rewardData, setRewardData] = useState(null);
  const [position, setPosition] = useState("  ");

  const horseBonus = useRef(0);

  const refCount = useRef();
  const refTips = useRef();
  const refDistance = useRef(0);

  const startGameAfterTutorial = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      if (props.showTier) {
        setUidVisible(0);
        setTierVisible(1);
      } else {
        setTutorial(!Cookies.get("tutorial"));
        if (Cookies.get("tutorial")) {
          startCountDown();
        } else {
          startGame();
        }
      }
    }, 500);
  }, []);

  useEffect(() => {
    if (typeof document == null) return;
    refWebGlApp.current = window.webglAPI;
    refWebGlApp.current.on(GAME_UPDATE_SCORE, updateScore);
    refWebGlApp.current.on(GAME_UPDATE_STATE, handleGameState);
    refWebGlApp.current.on(ON_BOARDING_STEP_DONE, handleGameState);
    refWebGlApp.current.on(GAME_UPDATE_RIDING_MODE, updateHorseBonus);

    refWebGlApp.current.on(GAME_PLAY_SOUND, handlePlaySound);
    refWebGlApp.current.on(GAME_PAUSE_SOUND, handlePauseSound);
    refWebGlApp.current.on(GAME_STOP_SOUND, handleStopSound);
    refWebGlApp.current.on(GAME_FADE_SOUND, handleFadeSound);
    refWebGlApp.current.on(GAME_SEEK_SOUND, handleSeekSound);

    return () => {
      refWebGlApp.current.off(GAME_UPDATE_SCORE, updateScore);
      refWebGlApp.current.off(GAME_UPDATE_STATE, handleGameState);
      refWebGlApp.current.off(ON_BOARDING_STEP_DONE, handleGameState);
      refWebGlApp.current.off(GAME_UPDATE_RIDING_MODE, updateHorseBonus);
      refWebGlApp.current.off(GAME_PLAY_SOUND, handlePlaySound);
      refWebGlApp.current.off(GAME_PAUSE_SOUND, handlePauseSound);
      refWebGlApp.current.off(GAME_STOP_SOUND, handleStopSound);
      refWebGlApp.current.off(GAME_FADE_SOUND, handleFadeSound);
      refWebGlApp.current.off(GAME_SEEK_SOUND, handleSeekSound);
    };
  }, []);

  function handlePlaySound(id) {
    SoundManager.play(id);
  }

  function handlePauseSound(id) {
    SoundManager.pause(id);
  }

  function handleStopSound(id) {
    SoundManager.stop(id);
  }

  function handleFadeSound(options) {
    const { id, volume, time, callback } = options;
    SoundManager.fade(id, volume, time, callback);
  }

  function handleSeekSound(options) {
    const { id, time } = options;
    SoundManager.seek(id, time);
  }

  function updateScore(value) {
    setScore(value);
    refDistance.current = value;
  }

  function updateHorseBonus(value) {
    horseBonus.current = value * 100;
  }

  /**
   * Show or hide the OneTrust Cookie Preferences button
   * @param {bool} shouldShowButton
   */
  function showCookiePreferenceButton(shouldShowButton) {
    const oneTrustElement = document.getElementById("onetrust-consent-sdk");
    if (shouldShowButton) {
      oneTrustElement.style.display = "block";
    } else {
      oneTrustElement.style.display = "none";
    }
  }

  function handleGameState(state) {
    if (state === GAME_STATES.GAME_OVER) {
      ContextMenu.activate();
      Broadcaster.emit(ON_UI_CHANGE, "hide");
      refWebGlApp.current.pause();
      setUidVisible(0);
      setTierVisible(1);
      Analytics.trackClickWithAction(
        "Gameplay",
        "Distance",
        refDistance.current
      );
      Analytics.trackPageView("/gameOverPage");

      // if (!Cookies.get("initials")) {
      //   Analytics.trackPageView("/initials");
      // }

      const market = props.market || Cookies.get("market");

      WebApi.getPrize(refDistance.current, market).then((res) => {
        setRewardData(res);
      });

      showCookiePreferenceButton(true);
    }

    if (state === ON_BOARDING_STEPS.MOVE_LEFT_RIGHT) {
      refTips.current.showRocks(true);
    }

    if (state === ON_BOARDING_STEPS.AVOID_ROCK) {
      refTips.current.showBottles();
    }

    if (state === ON_BOARDING_STEPS.COLECT_BOTTLE) {
      refTips.current.showHorse();

      setTimeout(() => {
        refTips.current.showRewards();
      }, 3000);
    }
  }

  function startCountDown() {
    setPlaying(1);
    refCount.current.start();
  }

  function startGame() {
    ContextMenu.deActivate();
    setUidVisible(1);
    setTierVisible(0);
    Broadcaster.emit(ON_UI_CHANGE, "show");
    if (startGameAfterTutorial.current) {
      refWebGlApp.current.onBoadingComplete();
    } else {
      refWebGlApp.current.startGame();
    }

    WebApi.newSession();
    showCookiePreferenceButton(false);
  }

  function restartGame() {
    ContextMenu.deActivate();

    setTierVisible(0);
    setUidVisible(1);
    Broadcaster.emit(ON_UI_CHANGE, "show");

    refWebGlApp.current.restartGame();
    refWebGlApp.current.resume();

    WebApi.newSession();
    showCookiePreferenceButton(false);
  }

  function handleEndTutorial() {
    Cookies.set("tutorial", 1);
    startGameAfterTutorial.current = true;
    startCountDown();
  }

  const HorseBonusExtraStyle = {
    width: `${horseBonus.current}%`,
  };

  return (
    <Wrapper>
      <CountDown
        started={playing}
        ref={refCount}
        callback={startGame}
        label={ready}
        go={go}
      />
      <UID visible={uidVisible}>
        <DistanceCounter meters={`${score}`} />
        <HorseBonus style={HorseBonusExtraStyle} />
        <Tips active={tutorial} tips={tips} callback={handleEndTutorial} />
      </UID>
      {tierVisible === 1 && (
        <Reward
          data={data}
          position={position}
          rewardData={rewardData}
          visible={tierVisible}
          callback={restartGame}
          distance={score}
          market={props.market}
        />
      )}
    </Wrapper>
  );
};

export default Home;
