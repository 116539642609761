const manifest = {
  audio: [
    {
      name: "ui_click",
      url: "assets/audio/ui_a",
    },
    {
      name: "boost",
      url: "assets/audio/bottle_powerup_a",
    },
    {
      name: "boost_alt",
      url: "assets/audio/bottle_powerup_b",
    },
    {
      name: "bottle_edp",
      url: "assets/audio/bottle_edp",
    },
    {
      name: "game_start",
      url: "assets/audio/game_start",
    },
    {
      name: "game_over",
      url: "assets/audio/game_over",
    },
    {
      name: "horse_mode",
      url: "assets/audio/horse_mode_b",
    },
    {
      name: "intro_soundscape_loop",
      url: "assets/audio/intro_soundscape_loop",
    },
    {
      name: "main_loop",
      url: "assets/audio/main_loop_b",
    },
    {
      name: "total_distance_medal",
      url: "assets/audio/total_distance_medal_loop",
    },
  ],
};

export default manifest;
