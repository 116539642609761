// ContextMenu.js
import { EventEmitter } from "events";

class ContextMenu extends EventEmitter {
	constructor() {
		super();
		if (typeof window !== "undefined")
			window.ContextMenu = this;
	}

	activate() {
		if (typeof window !== "undefined") {
			window.removeEventListener(
				"contextmenu",
				this.blockContextMenu
			);
			window.removeEventListener(
				"oncontextmenu",
				this.blockContextMenu
			);
		}
	}

	deActivate() {
		if (typeof window !== "undefined") {
			window.addEventListener(
				"contextmenu",
				this.blockContextMenu
			);
			window.addEventListener(
				"oncontextmenu",
				this.blockContextMenu
			);
		}
	}

	blockContextMenu(e) {
		e.preventDefault();
	}
}

const instance = new ContextMenu();

export default instance;
