import styled from "styled-components";

const Title = styled.p`
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 24px;
  max-width: 240px;
`;

const BottomGroup = styled.div`
  position: absolute;
  bottom: 25px;
  width: 100%;
  left: 0;
  padding: 0 24px;
`;

const OffsetContainer = styled.div`
  width: 100%;
  padding-top: 15%;
`;

const Coin = styled.img`
  display: block;
  margin: 0 auto 20px auto;
`;

export { Title, BottomGroup, OffsetContainer, Coin };
