import * as Cookies from "Utils/cookies";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EDPBottle from "./edp-bottle.gif";
import HeroBottle from "./hero-bottle.gif";

const Wrapper = styled.div`
  display: ${({ active }) => (active ? "block" : "none")};
  pointer-events: ${({ active }) => (active ? "initial" : "none")};
  user-select: ${({ active }) => (active ? "initial" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const DarkOverlay = styled.div`
  display: inline-block;
  width: 200vw;
  height: 100vh;
  background-color: #000000;
  position: absolute;
  transform: translate(-50%, 0);
  opacity: 15%;
  pointer-events: none;
`;

const Tip = styled.p`
  font-family: inherit;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  max-width: 100%;
  text-align: center;
  color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  transition: opacity 0.4s;
  pointer-events: none;
  user-select: none;
`;

const Bottle = styled.img`
  width: 70px;
`;

const TutorialPress = styled.div`
  width: 45px;
  height: 45px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  bottom: 50px;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition: opacity 0.4s;
  opacity: 0;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  pointer-events: none;
  user-select: none;
`;

const TutorialPressLeft = styled(TutorialPress)`
  left: 75px;
  @keyframes bhg-tutorial-left {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.2);
    }
  }
  animation-name: bhg-tutorial-left;
`;

const TutorialPressRight = styled(TutorialPress)`
  right: 75px;
  @keyframes bhg-tutorial-right {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0.8);
    }
  }
  animation-name: bhg-tutorial-right;
`;

/* Main Component */
const Tips = (props) => {
  const [visible, setVisible] = useState(true);
  const [currentTip, setCurrentTip] = useState(0);
  const [animation, setAnimation] = useState(false);
  const [tipVisible, setTipVisible] = useState(false);

  const tips = props.tips;
  const TOTAL_TIPS = tips.length;

  function handleNextClick() {
    if (currentTip < TOTAL_TIPS - 1) {
      nextTip();
    } else {
      if (props.callback) {
        setTipVisible(false);
        setAnimation(false);
        setTimeout(() => {
          props.callback();
          setVisible(false);
        }, 500);
      }
    }
  }

  function nextTip() {
    setTipVisible(false);
    setAnimation(false);
    setTimeout(() => {
      setCurrentTip(currentTip + 1);
    }, 500);
  }

  useEffect(() => {
    if (Cookies.get("tutorial")) return;
    setTimeout(() => {
      setTipVisible(true);
    }, 500);
    if (tips[currentTip]) {
      setAnimation(tips[currentTip].animation);
    }
    if (currentTip === 0) setTimeout(handleNextClick, 4500);
    else setTimeout(handleNextClick, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTip]);

  return (
    <Wrapper active={props.active && visible}>
      <DarkOverlay />
      <Tip visible={tipVisible}>
        {currentTip === 3 && (
          <Bottle src={EDPBottle} alt="Burberry EDP Bottle" />
        )}
        {currentTip === 2 && (
          <Bottle src={HeroBottle} alt="Burberry Hero Bottle" />
        )}
        <span dangerouslySetInnerHTML={{ __html: tips[currentTip].text }} />
      </Tip>
      <TutorialPressRight visible={animation} />
      <TutorialPressLeft visible={animation} />
    </Wrapper>
  );
};
export default Tips;
