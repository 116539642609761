/* Dependencies*/
import React from "react";
import styled from "styled-components";

export const H1 = styled.h1`
	font-family: inherit;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	margin: 0 32px 24px 32px;
`;

export const H2 = styled.h2`
	font-family: inherit;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	text-align: center;
	padding: 0 10px;
	margin: 2px 32px 24px 32px;
`;

export const P = styled.p`
	font-family: inherit;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 16px;
	margin-bottom: 20px;
`;

/* Main Component */
const Typography = (props) => {
	return <></>;
};
export default Typography;
