import "../../static/css/reset.css";
import "../../static/css/global.css";

import * as Cookies from "Utils/cookies";
import * as Typography from "Components/Typography/Typography";

import {
  APP,
  ON_ASSETS_LOADED,
  ON_GAME_LOADED,
  ON_LANDING_LOADED,
  ON_UI_CHANGE,
  REMOVE_LANDING,
} from "Utils/const";
import { PAUSED, UNPAUSED } from "Utils/const";
import React, { useEffect, useRef, useState } from "react";
import { StaticQuery, graphql } from "gatsby";

import Analytics from "Utils/analytics";
import Broadcaster from "Utils/Broadcaster";
import Container from "Components/Container/Container";
import { Helmet } from "react-helmet";
import MobileLandingStatic from "Assets/images/mobile-landing-static.png";
import MuteButton from "Components/MuteButton/MuteButton";
import PauseButton from "Components/PauseButton/PauseButton";
import PreCache from "Utils/precache";
import SoundManager from "Utils/SoundManager";
import manifest from "Utils/manifest";
import styled from "styled-components";
import useDeviceDetect from "./isMobile";

const WaterVideo = "/assets/textures/water.mp4";

const WebGL = styled.div`
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  z-index: 1;
  opacity: ${({ visible }) => (visible ? "1" : "0")};
  background-color: #000000;
  user-select: none;
`;

const UID = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  pointer-events: none;
  z-index: 100;
`;

const DarkOverlay = styled.div`
  display: inline-block;
  width: 200vw;
  height: 100vh;
  background-color: #000000;
  z-index: 100;
  position: absolute;
  transform: translate(-50%, 0);
  opacity: 15%;
  top: 0;
  left: 0;
  pointer-events: none;
`;

const Content = styled.div`
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  z-index: 100;
  user-select: none;

  animation-duration: 0.4s;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;

  pointer-events: ${({ gameVisible }) => (gameVisible ? "none" : "all")};
`;

const NoWebgl = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
  display: ${({ visible }) => (visible ? "block" : "none")};
  opacity: 0;
  pointer-events: none;

  animation-name: bhg-fadeIn;
  animation-duration: 0.4s;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
`;

const NoWebglHeader = styled(Typography.H2)`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 80%;
`;

const LandingImage = styled.div`
  background-image: url(${MobileLandingStatic});
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Layout = (props) => {
  let resizedOnce = false;
  const refContainer = useRef(null);
  const refWebGlApp = useRef(null);

  const [interfaceVisible, setInterfaceVisible] = useState(1);

  const { isMobile } = useDeviceDetect();

  const [muted, setMuted] = useState(Number(Cookies.get("muted")) || 0);
  const [paused, setPaused] = useState(0);
  const [landingLoaded, setLandingLoaded] = useState(false);

  const [shouldBeInteractive, setShouldBeInteractive] = useState(true);
  const [loaded, setLoaded] = useState(0);
  const [started, setStarted] = useState(false);
  const [showLanding, setShowLanding] = useState(true);

  const [userMuteChoice, setUserMuteChoice] = useState(
    Number(Cookies.get("muted")) || 0
  );

  const [LOCKED_WIDTH, LOCKED_HEIGHT] = [382, 500];
  const [width, setWidth] = useState(`${LOCKED_WIDTH}px`);
  const [height, setHeight] = useState("100vh");

  const [webgl, setWebgl] = useState(false);
  const [hasJavascript, setHasJavascript] = useState(false);
  const [injectedGameLibrary, setInjectedGameLibrary] = useState(false);
  const [libraryLoaded, setLibraryLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      // test if the functions are available
      try {
        if (window.webglAPI.isSupported()) {
          handleGameLoaded();
          clearInterval(interval);
        }
      } catch (e) {}
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setInjectedGameLibrary(true);
  }, []);

  useEffect(() => {
    Broadcaster.on(REMOVE_LANDING, resizeToNormalSize);

    return () => {
      Broadcaster.off(REMOVE_LANDING, resizeToNormalSize);
    };
  });

  useEffect(() => {
    if (typeof document == null) return;
    if (!libraryLoaded) return;

    refWebGlApp.current = window.webglAPI;
    const hasWebGl = refWebGlApp.current.isSupported();
    setWebgl(hasWebGl);
    setHasJavascript(true);

    if (hasWebGl) {
      refWebGlApp.current.init(refContainer.current);
      refWebGlApp.current.setBaseUrl("/");
      refWebGlApp.current.prepareVideo();
      refWebGlApp.current.loadLanding("/").then(() => {
        // Start landing page scene.
        refWebGlApp.current.startLanding();
        Broadcaster.emit(ON_LANDING_LOADED);

        setLandingLoaded(true);

        const assets = [WaterVideo];
        manifest.audio.map((a) => {
          assets.push(`/${a.url}.ogg`);
          assets.push(`/${a.url}.mp3`);
        });
        const soundCache = new PreCache();
        soundCache.init(assets);
        soundCache.startLoading().then(() => {
          refWebGlApp.current.setVideoBlob(soundCache.getBlob(WaterVideo).blob);
          manifest.audio.forEach((item) => {
            item.url = `/${item.url}`;

            SoundManager.addSound(item.url, item.name, {
              loop: item.name.indexOf("loop") > -1,
            });
          });
        });

        refWebGlApp.current.load("/").then(() => {
          setStarted(true);
          setLoaded(1);
          Broadcaster.emit(ON_ASSETS_LOADED);
        });
      });
    }
  }, [libraryLoaded]);

  useEffect(() => {
    if (typeof document == null) return;
    if (!loaded) return;

    if (props.gameVisible) {
      refWebGlApp.current.resume();
      setShouldBeInteractive(false);
      if (muted) {
        SoundManager.mute();
      } else {
        SoundManager.unmute();
      }
    } else {
      setShouldBeInteractive(true);
      refWebGlApp.current.pause();
    }
  }, [props.gameVisible]);

  useEffect(() => {
    if (muted) {
      SoundManager.mute();
      Cookies.set("muted", 1);
    } else {
      SoundManager.unmute();
      Cookies.set("muted", 0);
    }
  }, [muted]);

  useEffect(() => {
    Broadcaster.on(PAUSED, () => {
      setPaused(true);
    });
    Broadcaster.on(UNPAUSED, () => {
      setPaused(false);
    });
  }, []);

  useEffect(() => {
    if (!loaded) return;

    if (paused) {
      setMuted(true);
      // setShouldBeInteractive(true);
      refWebGlApp.current.pause();
      Broadcaster.emit(PAUSED);
    } else {
      setMuted(userMuteChoice);
      // setShouldBeInteractive(false);
      refWebGlApp.current.resume();
      Broadcaster.emit(UNPAUSED);
    }
  }, [paused]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  function resizeToNormalSize() {
    setShowLanding(false);
    handleResize();
  }

  function handleResize() {
    // deactivate resizing on mobile devices
    // if (isMobile && resizedOnce) return;

    // resizedOnce = true;

    let gameWidth = window.innerWidth;
    let gameHeight = Math.max(window.innerHeight, LOCKED_HEIGHT);

    let ratio = gameWidth / gameHeight;
    let maxRatio = 9 / 16;

    if (ratio > maxRatio) {
      gameWidth = gameHeight * maxRatio;
    }

    ratio = gameWidth / gameHeight;

    if (showLanding) {
      gameWidth = window.innerWidth;
    }

    setWidth(`${Math.round(gameWidth)}px`);
    setHeight(`${Math.round(gameHeight)}px`);

    if (!started) return;
    refWebGlApp.current.resize();
  }

  function toggleMute() {
    if (props.gameVisible) {
      Analytics.trackClick("Gameplay", "muteButton");
    } else {
      Analytics.trackClick("Homepage", "muteButton");
    }
    setMuted(!muted);
    setUserMuteChoice(!muted);
  }

  function togglePause() {
    Analytics.trackClick("Gameplay", "playPauseButton");
    // setShouldBeInteractive(!shouldBeInteractive);
    setPaused(!paused);
  }

  function handleGameLoaded() {
    setTimeout(() => {
      setLibraryLoaded(true);
      Broadcaster.emit(ON_GAME_LOADED);
      Broadcaster.on(ON_UI_CHANGE, handleUIChange);
    }, 1000);
  }

  function handleUIChange(e) {
    if (e == "show") {
      setInterfaceVisible(1);
    } else setInterfaceVisible(0);
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          localesJson {
            global {
              webglSupport
            }
          }
        }
      `}
      render={(data) => (
        <>
          <UID width={width} height={height}>
            <MuteButton
              onClick={toggleMute}
              visible={landingLoaded && interfaceVisible}
              fill={props.muteFill || "#ffffff"}
            />
            <PauseButton
              onClick={togglePause}
              visible={interfaceVisible && props.gameVisible}
            />
          </UID>
          <Container
            width={width}
            height={height}
            showGradient={props.gameVisible}
            shouldBeInteractive={shouldBeInteractive}
          >
            {injectedGameLibrary && (
              <Helmet>
                <script src="/assets/gamelibrary.js" type="text/javascript" />
              </Helmet>
            )}
            {isMobile && showLanding ? <LandingImage /> : ""}
            {shouldBeInteractive && <DarkOverlay />}
            <WebGL
              ref={refContainer}
              visible={webgl}
              width={width}
              height={height}
            />

            <Content
              gameVisible={props.gameVisible}
              width={width}
              height={height}
            >
              {props.children}
            </Content>
            <video
              id="video"
              loop
              crossOrigin="anonymous"
              playsInline
              muted
              autoPlay
              style={{ display: "none" }}
            ></video>
            <NoWebgl visible={!webgl && hasJavascript}>
              <NoWebglHeader>
                {data.localesJson.global.webglSupport}
              </NoWebglHeader>
            </NoWebgl>
          </Container>
        </>
      )}
    />
  );
};

export default Layout;
