import React, { useRef, useState } from "react";

import Logo from "Components/Logo/Logo";
import MobileLandingStatic from "Assets/images/mobile-landing-static.png";
import PrimaryButton from "Components/PrimaryButton/PrimaryButton";
import styled from "styled-components";

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	margin: 0 auto;
	background-color: #b19878;
	position: relative;
`;

const DarkOverlay = styled.div`
	display: inline-block;
	width: 100%;
	height: 100%;
	background-color: #000000;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 15%;
	pointer-events: none;
`;

const StaticImage = styled.div`
	background-size: cover;
	background-image: url(${MobileLandingStatic});
	width: 100%;
	height: 100%;
	filter: blur(4px);
	position: fixed;
	top: 0;
	left: 0;
`;

const HalfWrapper = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 382px;
	max-height: 667px;
	transform: translate(-50%, -50%);
`;

const Content = styled.div`
	width: 100%;
	font-size: 12px;
	line-height: 1rem;
	position: absolute;
	bottom: 0;
	padding: 32px 24px 24px 24px;
	margin: 0 auto;
	max-width: 382px;
	max-height: 667px;
`;

const Instructions = styled.p`
	text-align: center;
	color: #ffffff;
	font-weight: 500;
	padding: 0px 24px;
	margin-bottom: 24px;
`;

const Url = styled.input`
	background-color: transparent;
	color: #ffffff;
	text-align: center;
	border: 0;
	width: 100%;
	outline: 0;

	font-family: inherit;
	font-style: normal;
	font-weight: 500;
	font-size: 100%;
	line-height: 14px;

	margin-bottom: 24px;
`;

const BurberryLogo = styled.div`
	position: absolute;
	width: 225px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const InstagramWall = (props) => {
	let timeout = 0;

	const urlRef = useRef(null);
	const refCopy = useRef(null);

	const {
		url,
		instructions,
		copyLink,
		copiedLink,
	} = props.data.localesJson.instagramWall;

	const [label, setLabel] = useState(copyLink);

	function handleCopyLink() {
		refCopy.current.style.color = "#ffffff";
		refCopy.current.style.backgroundColor = "#000000";
		urlRef.current.select();
		document.execCommand("copy");

		window.getSelection().removeAllRanges();
		setLabel(copiedLink);

		clearTimeout(timeout);
		timeout = setTimeout(() => {
			setLabel(copyLink);
			refCopy.current.style.color = "#000000";
			refCopy.current.style.backgroundColor = "#ffffff";
		}, 2500);
	}

	return (
		<Wrapper>
			<StaticImage />
			<DarkOverlay />
			<HalfWrapper>
				<BurberryLogo>
					<Logo />
				</BurberryLogo>
				<Content>
					<Url ref={urlRef} value={url} readOnly />
					<Instructions>{instructions}</Instructions>
					<PrimaryButton
						ref={refCopy}
						label={label}
						onClick={handleCopyLink}
					/>
				</Content>
			</HalfWrapper>
		</Wrapper>
	);
};
export default InstagramWall;
