export const LANGUAGES = [
  { code: "en", value: "ENGLISH" },
  { code: "it", value: "ITALIANO " },
  { code: "ja", value: "日本語" },
  { code: "fr", value: "FRANÇAIS" },
  { code: "de", value: "DEUTSCH" },
  { code: "ko", value: "한국어" },
  { code: "es", value: "ESPAÑOL" },
  {
    code: "pt-br",
    value: "PORTUGUÊS (BRASIL)",
  },
  { code: "zh-tw", value: "繁體中文" },
  { code: "zh-cn", value: "简体中文" },
];

export const APP = "app";

export const GAME_UPDATE_SCORE = "game_update_score";
export const GAME_UPDATE_STATE = "game_update_state";
export const GAME_STATES = { GAME_OVER: "game_over" };

export const GAME_PLAY_SOUND = "game_play_sound";
export const GAME_PAUSE_SOUND = "game_pause_sound";
export const GAME_STOP_SOUND = "game_stop_sound";
export const GAME_FADE_SOUND = "game_fade_sound";
export const GAME_SEEK_SOUND = "game_seek_sound";

export const GAME_TOGGLE_RIDING_MODE = "game_toggle_riding_mode";
export const GAME_UPDATE_RIDING_MODE = "game_update_riding_mode";

export const ON_BOARDING_STEP_DONE = "on_boarding_step_done";

export const REMOVE_LANDING = "on_continue_click";

export const ON_BOARDING_STEPS = {
  MOVE_LEFT_RIGHT: "move_left_right",
  AVOID_ROCK: "avoid_rock",
  COLECT_BOTTLE: "colect_bottle",
};

export const ON_ASSETS_LOADED = "on_assets_loaded";
export const ON_GAME_LOADED = "on_game_loaded";
export const ON_LANDING_LOADED = "on_landing_loaded";
export const ON_UI_CHANGE = "on_ui_change";

export const HERO_SCORE = 1000;
export const INSTAGRAM_SCORE = 800;
export const BLACK_SCORE = 600;
export const GOLD_SCORE = 400;
export const SILVER_SCORE = 200;
export const BRONZE_SCORE = 0;

export const MEDAL_BLACK = "MEDAL_BLACK";
export const MEDAL_GOLD = "MEDAL_GOLD";
export const MEDAL_SILVER = "MEDAL_SILVER";
export const MEDAL_BRONZE = "MEDAL_BRONZE";

export const INSTAGRAM = "INSTAGRAM";
export const DRAW = "DRAW";
export const INSTORE = "INSTORE";
export const NOPRIZE = "NOPRIZE";

export const MUTED = "MUTED";
export const UNMUTED = "UNMUTED";

export const PAUSED = "PAUSED";
export const UNPAUSED = "UNPAUSED";
