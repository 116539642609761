/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.p`
	color: #ffffff;
	display: block;
	position: absolute;
	top: 25px;
	left: 50%;
	transform: translate(-50%, 0);

	[dir="rtl"] & {
		direction: ltr !important;
		text-align: left !important;
	}

	pointer-events: none;
	user-select: none;
`;

const DistanceNumber = styled.span`
	color: #ffffff;
	display: inline-block;
	font-family: inherit;
	font-style: normal;
	width: 20px;
	font-weight: 500;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	opacity: 0;
`;

const K2Distance = styled(DistanceNumber)`
	opacity: ${({ visible }) => (visible ? "1" : "0")};
	position: ${({ visible }) =>
		visible ? "relative" : "absolute"};
`;

const K1Distance = styled(DistanceNumber)`
	opacity: ${({ visible }) => (visible ? "1" : "0")};
	position: ${({ visible }) =>
		visible ? "relative" : "absolute"};
`;

const KDistance = styled(DistanceNumber)`
	opacity: ${({ visible }) => (visible ? "1" : "0")};
	position: ${({ visible }) =>
		visible ? "relative" : "absolute"};
`;

const HDistance = styled(DistanceNumber)`
	opacity: ${({ visible }) => (visible ? "1" : "0")};
	position: ${({ visible }) =>
		visible ? "relative" : "absolute"};
`;

const DDistance = styled(DistanceNumber)`
	opacity: ${({ visible }) => (visible ? "1" : "0")};
	position: ${({ visible }) =>
		visible ? "relative" : "absolute"};
`;

const MDistance = styled(DistanceNumber)`
	opacity: ${({ visible }) => (visible ? "1" : "0")};
`;

const Unit = styled(DistanceNumber)`
	opacity: 1;
	display: none;
`;

/* Main Component */
const DistanceCounter = (props) => {
	const { meters } = props;

	const meter = Math.floor((meters / 1) % 10);
	const dekameter = Math.floor((meters / 10) % 10);
	const hectometer = Math.floor((meters / 100) % 10);
	const kilometer = Math.floor((meters / 1000) % 10);
	const k1lometer = Math.floor((meters / 10000) % 10);
	const k2lometer = Math.floor((meters / 100000) % 10);

	const showMeter = meters > 0;
	const showDekameter = meters > 9;
	const showHectometer = meters > 99;
	const showKilometer = meters > 999;
	const showK1lometer = meters > 9999;
	const showK2lometer = meters > 99999;

	return (
		<>
			<Wrapper>
				<K2Distance visible={showK2lometer}>
					{k2lometer}
				</K2Distance>
				<K1Distance visible={showK1lometer}>
					{k1lometer}
				</K1Distance>
				<KDistance visible={showKilometer}>
					{kilometer}
				</KDistance>
				<HDistance visible={showHectometer}>
					{hectometer}
				</HDistance>
				<DDistance visible={showDekameter}>
					{dekameter}
				</DDistance>
				<MDistance visible={showMeter}>{meter}</MDistance>
				{/* <Unit>m</Unit> */}
			</Wrapper>
		</>
	);
};
export default DistanceCounter;
