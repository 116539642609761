import React from "react";
import styled from "styled-components";

/* Component Styles */
const Background = styled.div`
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${({ backgroundColor }) =>
		backgroundColor};

	z-index: 10;
`;

const Wrapper = styled.div`
	display: block;
	position: absolute;
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	z-index: 1000;
	left: 50%;
	transform: translate(-50%, 0);
	/* overflow: hidden; */
`;

const Panel = styled.div`
	display: inline-block;
	width: 50vw;
	height: 100vh;
	background-color: #000000;
	z-index: 100;
	position: absolute;
`;

const PanelLeft = styled(Panel)`
	left: 0;
	background: linear-gradient(
		210.21deg,
		#415b5c 13.97%,
		#92897e 78.87%
	);
`;

const PanelRight = styled(Panel)`
	right: 0;
	background: linear-gradient(
		204.31deg,
		#ded6ce 14.54%,
		#f4ece5 77.66%
	);
`;

const Content = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;

	user-select: ${({ shouldBeInteractive }) =>
		shouldBeInteractive ? "auto" : "none"};
`;

/* Main Component */
const Container = (props) => {
	return (
		<Content
			shouldBeInteractive={props.shouldBeInteractive}
		>
			<Background backgroundColor="#000000" />

			{props.showGradient ? (
				<>
					<PanelLeft />
					<PanelRight />
				</>
			) : (
				""
			)}

			<Wrapper width={props.width} height={props.height}>
				{props.children}
			</Wrapper>
		</Content>
	);
};
export default Container;
