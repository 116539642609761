import "../../static/css/terms.css";
import * as Cookies from "Utils/cookies";
import { StaticQuery, graphql } from "gatsby";
import { H2 } from "Components/Typography/Typography";
import Overlay from "Components/Overlay/Overlay";
import React from "react";
import styled from "styled-components";

const Title = styled(H2)``;

const Container = styled.div`
  display: block;
  width: 100%;
  height: ${({ confirmation }) =>
    confirmation ? "calc(100% - 105px)" : "calc(100% - 50px)"};
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

// const ContainerContinue = styled.div`
//   display: block;
//   width: 100%;
//   padding-top: 20px;
// `;

const Block = styled.div`
  margin-bottom: 35px;
`;

const TermsPage = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  isocode
                }
                html
              }
            }
          }
        }
      `}
      render={(data) => {
        const isocode = Cookies.get("isocode") || props.isocode;

        // let node = null;
        const markdowns = data.allMarkdownRemark.edges;
        // const len = markdowns.length;

        const availableMarkdowns = {};

        markdowns.map((m) => {
          const isomarket = m.node.frontmatter.isocode;
          const html = m.node.html;
          availableMarkdowns[isomarket] = html;
        });

        let content = availableMarkdowns[isocode];

        const blocks = <Block dangerouslySetInnerHTML={{ __html: content }} />;

        const { title } = props.data.localesJson.termsPage;

        function handleCallback() {
          if (props.confirmation) {
            window.navigateTo("/home", props.hrefData);
          } else if (props.callback) {
            props.callback();
          }
        }

        return (
          <Overlay
            withBackButton
            callback={handleCallback}
            visible={props.visible}
          >
            <Title>{title}</Title>
            <Container confirmation={props.confirmation} className="terms">
              {blocks}
            </Container>
          </Overlay>
        );
      }}
    />
  );
};

export default TermsPage;
