/* Dependencies*/
import React, { forwardRef, useEffect, useRef, useState } from "react";

import KeyboardStatus from "Utils/KeyboardStatus";
import styled from "styled-components";
import { useCombinedRefs } from "Utils/tools";

/* Component Styles */
const Wrapper = styled.div`
  display: block;
  position: relative;
`;

const Input = styled.input`
  display: block;
  position: relative;
  width: 100%;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: ${({ error }) => (error ? "#ED1C24" : "#000000")};
  outline: none;
  text-align: center;
  text-transform: uppercase;
`;

const Placeholder = styled.label`
  display: none;
  position: absolute;
  width: 100%;
  top: ${({ active }) => (active ? "-18px" : "1px")};
  left: 0px;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  pointer-events: none;
  user-select: none;
  color: #999999;
  transition: top 0.4s;
  text-align: ${({ align }) => align || "left"};
  [dir="rtl"] & {
    left: initial;
    right: 0px;
    text-align: ${({ align }) => align || "right"};
  }
`;

const MaxCharacters = styled(Placeholder)`
  left: initial;
  right: 0px;
  width: auto;
`;

const ErrorMessage = styled.p`
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #ed1c24;
  margin-top: 9px;
`;

const TextInput = forwardRef((props, ref) => {
  const [active, setActive] = useState(false);
  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, innerRef);
  const nbCharacters = useRef(null);

  useEffect(() => {
    setActive(combinedRef.current.value !== "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    combinedRef.current.addEventListener("keydown", updateCharacterCount);
    combinedRef.current.addEventListener("keyup", updateCharacterCount);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function updateCharacterCount() {
    if (!nbCharacters.current) return;
    setTimeout(() => {
      if (nbCharacters.current && combinedRef.current) {
        nbCharacters.current.innerHTML = `${combinedRef.current.value.length}/${props.maxlength}`;
      }
    }, 10);
  }

  function handleChange() {
    setActive(combinedRef.current.value !== "");
  }

  function handleFocus() {
    setActive(true);
    KeyboardStatus.setOpen(true);
  }

  function handleBlur() {
    setActive(combinedRef.current.value !== "");
    KeyboardStatus.setOpen(false);
  }

  return (
    <Wrapper className={props.className} error={props.error}>
      <Input
        ref={combinedRef}
        type={props.type}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={props.name}
        active={active}
        error={props.error}
        align={props.align}
        maxLength={props.maxlength}
        defaultValue={props.value}
        placeholder={props.hint}
      />
      <Placeholder active={active} align={props.align}>
        {props.placeholder}
      </Placeholder>
      {props.maxlength && (
        <MaxCharacters
          ref={nbCharacters}
        >{`0/${props.maxlength}`}</MaxCharacters>
      )}
      {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </Wrapper>
  );
});
export default TextInput;
