import BackButton from "Components/BackButton/BackButton";
import CloseButton from "Components/CloseButton/CloseButton";
/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */

const Wrapper = styled.div`
	display: ${({ visible }) => (visible ? "block" : "none")};
	pointer-events: ${({ visible }) =>
		visible ? "all" : "none"};
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100vw;
	height: ${({ height }) =>
		height ? `${height}px` : "100%"};

	overflow: hidden;
	z-index: 9000;

	background-color: #ffffff;

	@media (min-width: 767px) {
		max-width: 382px;
		max-height: 667px;
	}
`;

const Container = styled.div`
	position: absolute;
	z-index: 10;
	width: 100%;
	height: ${({ height }) =>
		height ? `${height}px` : "100%"};
	top: ${({ height }) => (height ? "50%" : "0")};
	left: 0;
	transform: ${({ height }) =>
		height ? "translate(0, -50%)" : "none"};

	padding: 20px 24px;
`;

/* Main Component */
const Overlay = ({
	children,
	withCloseButton,
	withBackButton,
	callback,
	className,
	height,
	visible,
}) => {
	let fHeight = height;
	if (typeof window !== "undefined") {
		if (window.innerWidth < 360) {
			fHeight = window.innerHeight;
		}
	}

	return (
		<Wrapper
			className={className}
			height={fHeight}
			visible={visible}
		>
			<Container height={height}>
				{withCloseButton && (
					<CloseButton onClick={callback} />
				)}
				{withBackButton && (
					<BackButton onClick={callback} />
				)}
				{children}
			</Container>
		</Wrapper>
	);
};
export default Overlay;

Overlay.defaultProps = {
	visible: true,
};
