/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.div`
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
	/* padding: 30px; */
	background: ${({ background }) => background};
`;

/* Main Component */
const Section = (props) => {
	return (
		<Wrapper background={props.background}>
			{props.children}
		</Wrapper>
	);
};
export default Section;
