import React from "react";
import TBLoaderBlack from "./loader-black.gif";
import TBLoaderWhite from "./loader-white.gif";

const LoadingAnimation = (props) => {
  if (props.black) {
    return <img src={TBLoaderBlack} alt="Loading GIF" />;
  } else {
    return <img src={TBLoaderWhite} alt="Loading GIF" />;
  }
};
export default LoadingAnimation;
