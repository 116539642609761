/* Dependencies*/
import React, { forwardRef } from "react";
import styled from "styled-components";
import * as Cookies from "Utils/cookies";
import TextInput from "Components/TextInput/TextInput";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inherit;
  font-style: normal;
  font-weight: ${({ highlighted }) => (highlighted ? "500" : "400")};
  font-size: ${({ highlighted }) => (highlighted ? "14px" : "12px")};
  line-height: 16px;
  color: #000000;
  margin-bottom: 16px;
`;

const Column = styled.span`
  flex-basis: 30%;
  text-align: center;
`;

const Form = styled.form`
  flex-basis: 30%;
  text-align: center;
`;

const LeaderboardLine = forwardRef((props) => {
  return (
    <Wrapper highlighted={props.highlighted}>
      <Column>{props.rank}</Column>
      <Column>{props.distance}</Column>
      {!Cookies.get("initials") && props.requiresInput ? (
        <>
          <Form onSubmit={props.handleInitialsInput}>
            <TextInput
              name="initials"
              type="text"
              align="left"
              maxlength="2"
              hint={props.initialsInputLabel}
              ref={props.refInitials}
              error={props.errorInitials}
              errorMessage={props.initialsInputError}
              value={props.userInitials}
            />
          </Form>
        </>
      ) : (
        <Column>{props.name}</Column>
      )}
    </Wrapper>
  );
});
export default LeaderboardLine;
