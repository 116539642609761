// Broadcaster.js
import { EventEmitter } from "events";

class Broadcaster extends EventEmitter {
  constructor() {
    super();
    if (typeof window !== "undefined") window.broadcaster = this;
  }
  emit(...args) {
    super.emit(...args);
  }
}

const instance = new Broadcaster();

export default instance;
