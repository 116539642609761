import * as Cookies from "Utils/cookies";

class WebApi {
	constructor() {
		this.api_url = "";
		this.uuid = null;
		this.token = null;
	}

	init(url) {
		this.api_url = url;
	}

	// create a new game session, token is used to check that the player is not cheating
	newSession() {
		return new Promise((resolve, reject) => {
			if (typeof window == "undefined") return resolve();
			const url = `${this.api_url}/new_session`;
			const body = {};

			postFetchCall(url, body).then((res) => {
				this.uuid = res.uuid;
				Cookies.set("uuid", res.uuid);
				this.token = res.token;
				return resolve(res);
			});
		});
	}

	// get the prize based on the score and the market using the token to verify the score
	getPrize(score, market) {
		return new Promise((resolve, reject) => {
			if (typeof window == "undefined") return resolve();
			const url = `${this.api_url}/get_prize`;
			const body = {
				uuid: this.uuid,
				token: this.token,
				score: score,
				market: market,
			};

			postFetchCall(url, body).then((res) => {
				this.token = res.token;
				return resolve(res);
			});
		});
	}

	// retrieve the prize url as an mp4 video for the medals or an instagram filter
	getPrizeUrl(score, initials, medal) {
		return new Promise((resolve, reject) => {
			if (typeof window == "undefined") return resolve();
			const url = `${this.api_url}/get_prize_url`;
			const body = {
				uuid: this.uuid,
				token: this.token,
				score: score,
				initials: initials,
				prize: medal,
			};

			postFetchCall(url, body).then((res) => {
				return resolve(res);
			});
		});
	}

	// retrieve the top 10 best highscores
	getLeaderboard() {
		return new Promise((resolve, reject) => {
			if (typeof window == "undefined") return resolve();
			const url = `${this.api_url}/get_leaderboard`;
			const body = {};

			getFetchCall(url, body).then((res) => {
				return resolve(res);
			});
		});
	}

	// enter the enter leaderboard and also the current top ten best scores
	enterLeaderboard(score, initials) {
		return new Promise((resolve, reject) => {
			if (typeof window == "undefined") return resolve();
			const url = `${this.api_url}/enter_leaderboard`;
			const body = {
				uuid: this.uuid,
				token: this.token,
				score: score,
				initials: initials,
			};

			const _scope = this;

			postFetchCall(url, body).then((res) => {
				const { position } = res;
				_scope.getLeaderboard().then((res) => {
					// save the highest score
					Cookies.set(
						"highestScore",
						Math.max(score, Cookies.get("highestScore"))
					);

					if (score >= Cookies.get("highestScore")) {
						// save the highest position
						Cookies.set("highestPosition", position);
					}

					const highscores = res.highscores;
					return resolve({ position, highscores });
				});
			});
		});
	}
}

function postFetchCall(route, body) {
	return new Promise((resolve, reject) => {
		fetch(route, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: body ? JSON.stringify(body) : null,
		}).then((rawResponse) => {
			const response = rawResponse.json();
			resolve(response);
		});
	});
}

function getFetchCall(route, body) {
	return new Promise((resolve, reject) => {
		fetch(route, {
			method: "GET",
		}).then((rawResponse) => {
			const response = rawResponse.json();
			resolve(response);
		});
	});
}

export default new WebApi();
