import * as Cookies from "Utils/cookies";

import { ON_ASSETS_LOADED, ON_GAME_LOADED, REMOVE_LANDING } from "Utils/const";
import React, { useEffect, useRef, useState } from "react";
import Analytics from "Utils/analytics";
import Broadcaster from "Utils/Broadcaster";
import Home from "Views/Home";
import Landing from "Views/Landing";
import Languages from "Views/Languages";
import Page from "Utils/page";
import Preparing from "Views/Preparing";
import SEO from "Utils/seo";
import Splash from "Views/Splash";
import Terms from "Views/Terms";
import WebApi from "Utils/webapi";
import styled from "styled-components";

const Views = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
`;

const SitePage = (props) => {
  const isocode = props.pageContext.isocode;
  const rewards = props.pageContext.rewards;
  const market = props.pageContext.market;

  const tutorial = Boolean(!Cookies.get("tutorial"));

  const { data } = props;
  const { title } = data.localesJson.metadata;
  // let {
  // 	ukPosStores,
  // 	ukSelectedBootsStores,
  // } = data.localesJson.tiers;

  let defaultView = null;
  let defaultVisibility = false;

  const [muteFill, setMuteFill] = useState("#FFFFFF");

  defaultView = (
    <Splash
      data={data}
      isocode={isocode}
      animate
      ga_uid={props.pageContext.ga_uid}
    />
  );

  const refWebGlApp = useRef(null);
  const previous = useRef(defaultView);
  const current = useRef(defaultView);
  const [view, setView] = useState(defaultView);
  const [storesVisible, setStoresVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [termsHrefData, setTermsHrefData] = useState(null);
  const [confirmation, setConfirmation] = useState("");

  const [gameVisible, setGameVisible] = useState(defaultVisibility || false);

  const [gameLoaded, setGameLoaded] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    Broadcaster.on(ON_ASSETS_LOADED, handleAssetsLoaded);
    Broadcaster.on(ON_GAME_LOADED, handleGameLoaded);

    return () => {
      Broadcaster.off(ON_ASSETS_LOADED, handleAssetsLoaded);
      Broadcaster.off(ON_GAME_LOADED, handleGameLoaded);
    };
  }, []);

  useEffect(() => {
    if (gameVisible && gameLoaded) {
      window.navigateTo("/home");
    }
  }, [gameLoaded, assetsLoaded]);

  useEffect(() => {
    window.navigateTo = (href, hrefData) => {
      let currentView = null;
      let visibleGame = false;
      let animate = true;

      setTermsVisible(false);
      setMuteFill("#ffffff");

      switch (href) {
        case "/preparing":
          visibleGame = true;
          if (gameLoaded && assetsLoaded) {
            initiateGame();
            Analytics.trackPageView("/game");
            currentView = <Home market />;
          } else {
            Analytics.trackPageView("/preparing");
            currentView = <Preparing />;
          }
          break;
        case "/languages":
          Analytics.trackPageView("/languages");
          currentView = <Languages />;
          break;
        case "/home":
          initiateGame();
          Analytics.trackPageView("/home");
          currentView = <Home showTier={hrefData} market />;
          visibleGame = true;
          break;
        case "/terms":
          Analytics.trackPageView("/terms");
          toggleTerms();
          return;
        case "/stores":
          Analytics.trackPageView("/stores");
          toggleStores();
          return;
        case "back()":
          currentView = previous.current;
          animate = false;
          break;
        case "/":
        case "/landing":
        default:
          setMuteFill("#000000");
          Analytics.trackPageView("/introPage");
          currentView = <Landing market />;
          break;
      }

      const currentViewProps = React.cloneElement(currentView, {
        isocode,
        animate,
        data,
        hrefData,
        rewards,
        market,
      });

      previous.current = current.current;

      if (hrefData != "tier") {
        setGameVisible(visibleGame);
      }

      setView(currentViewProps);
    };

    // initialize webapi
    const api_endpoint = props.pageContext.api_endpoint;
    WebApi.init(api_endpoint);
  });

  function toggleTerms(data) {
    setTermsVisible(!termsVisible);
    setTermsHrefData(data);
  }

  function toggleStores() {
    setStoresVisible(!storesVisible);
  }

  function handleAssetsLoaded() {
    setAssetsLoaded(true);
  }
  function handleGameLoaded() {
    setGameLoaded(true);
  }

  function initiateGame() {
    if (!hasStarted) {
      refWebGlApp.current = window.webglAPI;
      Broadcaster.emit(REMOVE_LANDING);
      refWebGlApp.current.resize({
        width: window.innerHeight * 0.56,
        height: window.innerHeight,
      });
      refWebGlApp.current.start({ onBoarding: tutorial });
      setHasStarted(true);
    }
  }

  current.current = view;

  const lang = isocode;

  return (
    <>
      <meta name="market" content={market} />
      <Page
        gameVisible={gameVisible}
        javascriptSupport={props.pageContext.javascriptSupport}
        rotateDevice={props.pageContext.rotateDevice}
        muteFill={muteFill}
      >
        <SEO title={title} lang={lang} />
        <Views>{view}</Views>
      </Page>
      <Terms
        data={data}
        isocode={isocode}
        visible={termsVisible}
        callback={toggleTerms}
        confirmation={confirmation}
        hrefData={termsHrefData}
      />
      {/* <Stores
        title={ukSelectedBootsStores}
        data={ukPosStores}
        visible={storesVisible}
        callback={toggleStores}
      /> */}
    </>
  );
};

export default SitePage;
