import ReactGA from "react-ga";
class Analytics {
  constructor() {}

  setup(ga_uid) {
    ReactGA.initialize(ga_uid, {
      gaOptions: { cookieFlags: "Secure;SameSite=None" },
    });
  }

  trackPageView(path) {
    const lang = window.location.pathname.split("/")[1];
    ReactGA.pageview(`/${lang}${path}`);
  }

  trackValue(category, action, value) {
    ReactGA.event({
      category: category,
      action: action,
      value: value,
    });
  }

  trackClick(category, label) {
    ReactGA.event({
      category: category,
      action: "click",
      label: label,
    });
  }

  trackClickWithAction(category, action, label) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }

  trackError(error) {
    ReactGA.event({
      category: "Errors",
      action: "message",
      label: error,
    });
  }
}

const instance = new Analytics();

export default instance;
