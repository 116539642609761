import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as Cookies from "Utils/cookies";
import { PAUSED, UNPAUSED } from "Utils/const";
import Broadcaster from "Utils/Broadcaster";
import KeyboardStatus from "Utils/KeyboardStatus";
import SoundManager from "Utils/SoundManager";

const Wrapper = styled.div`
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  display: ${({ visible }) => (visible ? "block" : "none")};

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 1;
    z-index: 10;
  }
`;

const Message = styled.div`
  display: "block";
  position: absolute;
  width: 230px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Burberry Styrene B Cyr App;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  z-index: 100;
`;

const PleaseRotate = (props) => {
  const [visible, setVisible] = useState(0);
  const gameActive = useRef(false);

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)");

    handleOrientationChange(portrait.matches);

    try {
      portrait.addEventListener("change", function (e) {
        handleOrientationChange(e.matches);
      });
    } catch {
      portrait.addListener(function (e) {
        handleOrientationChange(e.matches);
      });
    }

    return () => {
      try {
        portrait.removeEventListener("change", handleOrientationChange);
      } catch {
        portrait.removeListener(handleOrientationChange);
      }
    };
  }, [visible]);

  useEffect(() => {
    gameActive.current = props.gameVisible;
  }, [props.gameVisible]);

  function handleOrientationChange(isPortrait) {
    if (KeyboardStatus.getOpen()) return;
    if (Cookies.get("mdv") != "1") return;

    if (!isPortrait) {
      if (Cookies.get("muted") == 0) SoundManager.mute();
      setVisible(1);
      if (gameActive.current) {
        Broadcaster.emit(PAUSED);
      }
    } else {
      if (Cookies.get("muted") == 0) SoundManager.unmute();
      setVisible(0);
      if (gameActive.current) {
        Broadcaster.emit(UNPAUSED);
      }
    }
  }

  return (
    <Wrapper visible={visible}>
      <Message>{props.message}</Message>
    </Wrapper>
  );
};

export default PleaseRotate;
