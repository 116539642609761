import InstagramWall from "Components/InstagramWall/InstagramWall";
import { graphql } from "gatsby";
import React from "react";
import Site from "Views/Site";

const IndexPage = (props) => {
  let isInAppBrowser = false;
  let userAgent = "";

  if (typeof window !== "undefined") {
    userAgent = String(window.navigator.userAgent).toLowerCase();
    isInAppBrowser =
      userAgent.indexOf("instagram") != -1 ||
      userAgent.indexOf("facebook") != -1;
  }

  const CurrentView = isInAppBrowser ? (
    <InstagramWall data={props.data} />
  ) : (
    <Site pageContext={props.pageContext} data={props.data} />
  );

  return <>{CurrentView}</>;
};

export default IndexPage;

export const pageQuery = graphql`
  query($isocode: String) {
    localesJson(metadata: { isocode: { eq: $isocode } }) {
      metadata {
        isocode
        defaultMarket
        language
        title
        description
      }
      global {
        javascriptSupport
        webglSupport
        rotateDevice
        brandUrl
        brandName
        playAgainButton
      }
      markets {
        isocode {
          code
          value
        }
      }
      landingPage {
        languageSelect
        languageContinueButton
        termsConfirm
        errorTermsConfirm
        ageConfirm
        errorAgeConfirm
        playButton
        terms
      }
      introPage {
        title
        instructionOne
        instructionTwo
        startButton
      }
      gamePage {
        ready
        go
        tipOne
        tipTwo
        tipThree
        tipFour
        tipFive
      }
      gameOverPage {
        gameEndTitle
        shareButton
        leaderboardButton
      }
      leaderboardPage {
        highScoresTitle
        rankHeading
        distanceHeading
        nameHeading
        userLastScoreHeading
        userHighScoreHeading
        initialsInputLabel
        initialsInputError
      }
      socialShare {
        scoreHeading
        linkHeading
        link
      }
      termsPage {
        title
      }
      instagramWall {
        url
        instructions
        copyLink
        copiedLink
      }
    }
  }
`;
