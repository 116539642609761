import React, { useRef, useEffect } from "react";
import { saveAs } from "file-saver";
import Canvas from "../Canvas/Canvas";
import Analytics from "Utils/analytics";

import {
  BLACK_SCORE,
  GOLD_SCORE,
  SILVER_SCORE,
  BRONZE_SCORE,
} from "../../utils/const";
import bronze from "../../../static/assets/images/share_coins/bronze.jpg";
import silver from "../../../static/assets/images/share_coins/silver.jpg";
import gold from "../../../static/assets/images/share_coins/gold.jpg";
import black from "../../../static/assets/images/share_coins/black.jpg";

const WebShare = ({ scoreHeading, score, linkHeading, link, finished }) => {
  const getImagePath = () => {
    if (score >= BLACK_SCORE) return black;
    else if (score >= GOLD_SCORE) return gold;
    else if (score >= SILVER_SCORE) return silver;
    else if (score >= BRONZE_SCORE) return bronze;
    else return;
  };

  const canvas = (canvas) => {
    const image = new Image();
    image.src = getImagePath();
    image.onload = () => {
      const context = canvas.getContext("2d");
      const fontWeight = "500";
      const fontFamily = "Burberry Styrene B Cyr App";
      const xAxisCenter = image.width / 2;

      canvas.height = image.height;
      canvas.width = image.width;

      context.drawImage(image, 0, 0);
      context.fillStyle = "#FFFFFF";
      context.textAlign = "center";
      // Score heading
      context.font = `${fontWeight} 46px ${fontFamily}`;
      context.fillText(scoreHeading, xAxisCenter, 780);
      // Score
      context.font = `${fontWeight} 115px ${fontFamily}`;
      context.fillText(score, xAxisCenter, 895);
      // Link heading and Link
      context.font = `${fontWeight} 33px ${fontFamily}`;
      context.fillText(linkHeading, xAxisCenter, 1240);
      context.fillText(link, xAxisCenter, 1290);

      canvas.toBlob((blob) => {
        let file = new File([blob], "Burberry Hero Game.jpg", {
          type: "image/jpeg",
        });
        let files = [file];
        if (navigator.canShare && navigator.canShare({ files: files })) {
          Analytics.trackClickWithAction("Gameplay", "native share", "true");
          navigator.share({
            files: files,
          });
        } else {
          Analytics.trackClickWithAction("Gameplay", "native share", "false");
          saveAs(file);
        }
      }, "image/jpeg");

      finished();
    };
  };

  return <Canvas canvas={canvas} />;
};

export default WebShare;
