/* Dependencies*/
import React, { forwardRef, useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useCombinedRefs } from "Utils/tools";

/* Component Styles */
const Wrapper = styled.div`
  display: block;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    display: block;
    right: 3px;
    top: 0px;
    width: 8px;
    height: 8px;
    border-style: solid;
    border-color: ${({ error }) => (error ? "#ED1C24" : "#000000")};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    pointer-events: none;
  }

  [dir="rtl"] & {
    &:after {
      left: 0px;
      right: initial;
    }
  }
`;

const Select = styled.select`
  display: block;
  cursor: pointer;

  color: ${({ active }) => (active ? "#000000" : "#999999")};

  font-family: inherit;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;

  width: 100%;
  padding: 0;

  padding-bottom: 8px;

  border: 0;
  border-radius: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({ active }) => (active ? "#000000" : "#cccccc")};
  border-bottom-color: ${({ error }) => (error ? "#ED1C24" : "initial")};

  appearance: none;
  background-color: transparent;

  & option {
    color: #000000;
  }

  &:focus {
    border-bottom: 1px solid #000000;
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
`;

const ErrorMessage = styled.p`
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #ed1c24;
  margin-top: 9px;
`;

/* Main Component */
const Dropdown = forwardRef((props, ref) => {
  const [active, setActive] = useState(false);

  const innerRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, innerRef);

  useEffect(() => {
    setActive(combinedRef.current.value != "");
  });

  function handleChange() {
    setActive(combinedRef.current.value != "");
    if (props.onChange) props.onChange();
  }

  return (
    <Wrapper error={props.error} className={props.className}>
      <Select
        ref={combinedRef}
        active={active}
        preselected={props.preselected}
        onChange={handleChange}
        error={props.error}
        value={props.value}
      >
        {props.children}
      </Select>
      {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </Wrapper>
  );
});
export default Dropdown;
