import { MUTED, UNMUTED } from "Utils/const";

import Broadcaster from "Utils/Broadcaster";
import { gsap } from "gsap";
class SoundManager {
	constructor() {
		if (typeof window !== "undefined") {
			window.SoundManager = this;
			this.Sono = require("sono/dist/sono.js");
		}
		this.sounds = {};
		this.faded = {};
	}
	unmute() {
		if (typeof window == "undefined") {
			return;
		}
		this.isMuted = false;
		this.Sono.unMute();
		Broadcaster.emit(UNMUTED);
	}
	mute() {
		if (typeof window == "undefined") {
			return;
		}
		this.isMuted = true;
		this.Sono.mute();
		Broadcaster.emit(MUTED);
	}
	addSound(url, id, options) {
		if (this.disabled || this.sounds[id]) return;

		if (typeof window == "undefined") {
			return;
		}

		options = options || {};

		const src = [`${url}.ogg`, `${url}.mp3`];
		const volume =
			options.volume !== undefined ? options.volume : 1;
		const sound = this.Sono.createSound({
			src,
			autoplay: options.autoplay || false,
			loop: options.loop || false,
			volume,
		});

		if (options.autoplay) {
			sound.play();
		}

		sound.realVolume = volume;
		this.sounds[id] = sound;
	}
	seek(id, time) {
		if (this.sounds[id]) {
			this.sounds[id].seek(time);
		}
	}
	fade(id, volume, time, callback) {
		if (this.sounds[id]) {
			this.faded[id] = true;
			gsap.killTweensOf(this.sounds[id]);
			gsap.to(this.sounds[id], time, {
				volume,
				onComplete: () => {
					if (callback) {
						callback();
					}
				},
			});
		}
	}
	play(id) {
		if (this.sounds[id]) {
			if (this.faded[id]) {
				this.fade(id, 100, 1);
			} else {
				this.sounds[id].play();
			}
		}
	}
	pause(id) {
		if (this.sounds[id]) {
			this.sounds[id].pause();
		}
	}
	stop(id) {
		if (this.sounds[id]) {
			this.sounds[id].stop();
		}
	}
}

export default new SoundManager();
