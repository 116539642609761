import * as Cookies from "Utils/cookies";
import React, { useRef, useState } from "react";
import Dropdown from "Components/Dropdown/Dropdown";
import { H2 } from "Components/Typography/Typography";
import { LANGUAGES } from "Utils/const";
import Overlay from "Components/Overlay/Overlay";
import SecondaryButton from "Components/SecondaryButton/SecondaryButton";
import styled from "styled-components";

const Language = styled.div`
  margin-bottom: 53px;
`;

const BottomContent = styled.div`
  width: 100%;
`;

const Languages = (props) => {
  const isocode = props.isocode;
  const [localeIsoCode, setLocaleIsoCode] = useState(isocode);
  const refLocale = useRef();

  function confirmLanguage() {
    const selectedIsoCode = refLocale.current.value;
    // if select language is same as before, simple navigation back to home
    if (selectedIsoCode == isocode) {
      window.navigateTo("back()");
    } else {
      Cookies.set("isocode", selectedIsoCode);
      window.location = `../${selectedIsoCode}`;
    }
  }

  function changeLanguage() {
    const selectedIsoCode = refLocale.current.value;
    setLocaleIsoCode(selectedIsoCode);
  }

  const { landingPage } = props.data.localesJson;
  const { languageSelect, languageContinueButton } = landingPage;

  const lang_options = LANGUAGES.map((lang, key) => {
    return (
      <option key={key} value={lang.code}>
        {lang.value}
      </option>
    );
  });

  return (
    <Overlay className={props.className} height={236}>
      <Language>
        <H2>{languageSelect}</H2>
        <Dropdown
          value={localeIsoCode}
          ref={refLocale}
          onChange={changeLanguage}
        >
          {lang_options}
        </Dropdown>
      </Language>
      <BottomContent>
        <SecondaryButton
          onClick={confirmLanguage}
          label={languageContinueButton}
        />
      </BottomContent>
    </Overlay>
  );
};

export default Languages;
