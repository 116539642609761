import { PAUSED, UNPAUSED } from "Utils/const";
/* Dependencies*/
import React, { useState } from "react";

import Broadcaster from "Utils/Broadcaster";
import styled from "styled-components";
import { useEffect } from "react";

/* Component Styles */
const Wrapper = styled.div`
	position: absolute;
	display: ${({ visible }) => (visible ? "block" : "none")};

	z-index: 9000;
	top: 24px;
	left: 22px;
	width: 16px;
	height: 16px;
	cursor: pointer;
	pointer-events: all;

	[dir="rtl"] & {
		left: initial;
		right: 37px;
	}

	&:hover {
		svg {
			opacity: 0.5;
		}
	}
`;

const Play = styled.svg`
	position: absolute;
	left: 2px;
	top: -1px;
	display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Pause = styled.svg`
	position: absolute;
	display: ${({ visible }) => (visible ? "block" : "none")};
`;

/* Main Component */
const PauseButton = (props) => {
	const width = props.width || "18";
	const height = props.height || "18";
	const viewBox = `0 0 ${width} ${height}`;

	const [paused, setPaused] = useState(0);

	function togglePause() {
		setPaused(!paused);
		props.onClick();
	}

	useEffect(() => {
		Broadcaster.on(PAUSED, () => {
			setPaused(true);
		});

		Broadcaster.on(UNPAUSED, () => {
			setPaused(false);
		});
	}, []);

	return (
		<Wrapper onClick={togglePause} visible={props.visible}>
			<Pause
				visible={!paused}
				width={width}
				height={height}
				viewBox={viewBox}
				fill="#FFFFFF"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13 16L13 0L11 -8.74228e-08L11 16L13 16ZM5 16L5 -3.49691e-07L3 -4.37114e-07L3 16L5 16Z"
				/>
			</Pause>
			<Play
				visible={paused}
				width={width}
				height={height}
				viewBox={viewBox}
				fill="#FFFFFF"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 0L13 9L0 18V0ZM2.04021 3.87548V14.1245L9.4423 9L2.04021 3.87548Z"
				/>
			</Play>
		</Wrapper>
	);
};
export default PauseButton;
