import Analytics from "Utils/analytics";
/* Dependencies*/
import React from "react";
import styled from "styled-components";

/* Component Styles */
const Wrapper = styled.span`
  display: inline-block;
  width: ${({ nodecoration }) => (nodecoration ? "100%" : "initial")};
`;

const Anchor = styled.a`
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  text-decoration: ${({ nodecoration }) =>
    nodecoration ? "none" : "underline"};

  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

/* Main Component */
const AppLink = (props) => {
  let anchor = null;

  const {
    href,
    hrefData,
    children,
    nodecoration,
    track,
    onClick,
    downloadable,
  } = props;

  // external
  if (String(href).substr(0, 4) == "http") {
    anchor = (
      <Anchor
        onClick={(e) => {
          // if no link, deactivate
          if (!href) {
            e.preventDefault();
            return;
          }
          track && Analytics.trackClick(track.category, track.label);
          if (downloadable) {
            e.preventDefault();
            window.location.href = href;
            return;
          }
        }}
        href={href}
        target="_blank"
        nodecoration={nodecoration}
      >
        {children}
      </Anchor>
    );
  } else {
    // internal
    anchor = (
      <Anchor
        nodecoration={nodecoration}
        href={href}
        onClick={(e) => {
          // if no link, deactivate
          if (!href) {
            e.preventDefault();
            return;
          }
          track && Analytics.trackClick(track.category, track.label);
          e.preventDefault();
          window.navigateTo(href, hrefData);
        }}
      >
        {children}
      </Anchor>
    );
  }

  return (
    <Wrapper
      className={props.className}
      nodecoration={nodecoration}
      onClick={props.onClick}
    >
      {anchor}
    </Wrapper>
  );
};
export default AppLink;
